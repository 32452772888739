import React, { useEffect, useState } from 'react'
import { Images } from '../../store/Constants'
import axios from '../../config/server'
import { Link } from 'react-router-dom'
import '../../assets/css/style.css';
import '../../assets/css/profile.css';
import { useDarkMode } from '../../contexts/DarkModeContext';


const BeefList = () => {
  const [liveDebates, setLiveDebates] = useState([])
  const [scheduledDebates, setScheduledDebates] = useState([])

  const { darkMode, toggleDarkMode } = useDarkMode(); 

    useEffect(() => {
        getDebates()
    }, [])
    const getDebates = async () =>{
      try {
        const response = await axios.get(`/debates/`, {
          params : {
            Live : true,
            Scheduled : true
            // Completed : true,
          }
        })
        if (response.status === 200) {
          setLiveDebates(response?.data?.data?.filter((item) => item?.status === "Live"))
          setScheduledDebates(response?.data?.data?.filter((item) => item?.status === "Scheduled"))
          console.log("Live Debates List ===>", response?.data?.data?.filter((item) => item?.status === "Live"));
          console.log("Up Coming Debates List ===>", response?.data?.data?.filter((item) => item?.status === "Scheduled"));
        }
      } catch (error) {
        console.log('Error', error);
      }
    }

    const imageStyle = {
      margin: 0,
      padding: 0,
    };

  return (
    <div className={`main ${darkMode ? 'dark-mode' : ''}`}>

      <main className="content mt-5">
        <div className="container">
          <div className="row mt-5" style={{marginLeft: "200px"}}> 
            <div className="col-md-12">
              {/* <!-- Nav tabs --> */}
              <ul className="nav nav-pills tab-btns" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className={`nav-link active ${darkMode ? 'text-white' : ''}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                    type="button" role="tab" aria-controls="home" aria-selected="true">
                    Live Debates
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className={`nav-link ${darkMode ? 'text-white' : ''}`} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button"
                    role="tab" aria-controls="profile" aria-selected="false">
                    Up Coming
                  </button>
                </li>
              </ul>

              {/* <!-- Tab panes --> */}
              <div className="tab-content mt-5">
                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <p>Beef - Debate or Argue. Let People Vote Who’s Right!</p>
                  <h3>Where’s The BEEF?</h3>
                  <p>Live Debates On Right Now!</p>

                  <div className="row g-3 mb-2">
                
                    {
                      liveDebates.length > 0 && liveDebates.map((debate)=>{
                        return (
                          <div className="col-md-4">
                    <div className="card-box mt-4">
                    <div className="row">
                        <div className="col card-img-left" style={imageStyle}>
                        <img className="w-100 h-100" src={debate?.debater?.image} alt="Avatar" />
                        </div>
                        <div className="col card-img-right" style={imageStyle}>
                        <img className="w-100 h-100" src={debate?.debatee.image} alt="Avatar" />
                        </div>
                    </div>
                    <div className="overlay">
                        <div className="row">
                        <div className="col"><img src={Images.microphonePNG} alt="" />{debate?.status}</div>
                                    <div className="col text-end">
                                        <img
                                            src={Images.dot}
                                            alt="Options"
                                            // onClick={() => handleShowDeleteButton(debate._id)}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </div>
                                   
                        <div className="col-md-12 mt-4">
                            <h3><b>{debate.topic}</b></h3>
                        </div>
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="debate-winner">
                        <div className="row">
                            <div className="d-flex justify-content-between">
                            <p className="m-0 losing-tag">{ debate?.debaterVotes?.length === debate?.debateeVotes?.length ? "Debater" : debate?.debaterVotes?.length > debate?.debateeVotes?.length ? "Wining"  : "Losing" }</p>
                            <p className="m-0 losing-tag">{debate?.debateeVotes?.length === debate?.debaterVotes?.length ? "Debatee" : debate?.debateeVotes?.length > debate?.debaterVotes?.length ? "Wining" : "Losing"}</p>
                            </div>
                            <div className="col d-flex justify-content-between align-items-end ">
                            <div className="user d-flex align-items-center mt-3">
                                <img className="user-img" src={debate?.debater?.image} alt="" />
                                <div className="user-detail text-white ps-2">
                                <p className="m-0 name"><b>{debate?.debater?.name}</b></p>
                                <p className="m-0 username">{debate?.debater?.userName}</p>
                                </div>
                            </div>
                            <img src={Images.Versus} alt="" />
                            <div className="user d-flex justify-content-end align-items-center mt-3">
                                <div className="user-detail text-white text-end px-2">
                                <p className="m-0 name"><b>{debate?.debatee?.name}</b></p>
                                <p className="m-0 username">{debate?.debatee?.userName}</p>
                                </div>
                                <img className="user-img" src={debate?.debatee?.image} alt="" />
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
                    </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className="tab-pane fade show" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <p>Beef - Debate or Argue. Let People Vote Who’s Right!</p>
                  <h3>Add to calender</h3>
                  <p>Upcoming Debates for you!</p>
                  <div className="row g-3 mb-2">
                    {
                      scheduledDebates.length > 0 && scheduledDebates.map((debate) => {
                        return (
                          <div className="col-md-4">
                      <div className="card-box">
                        <div className="row">
                          <div className="col card-img-left" style={imageStyle}>
                            <img className="w-100 h-100" src={debate?.debater?.image} alt="Avatar" />
                          </div>
                          
                          <div className="col card-img-right" style={imageStyle}>
                            <img className="w-100 h-100" src={debate?.debatee?.image} alt="Avatar" />
                          </div>
                        </div>
                        <div className="overlay">
                          <div className="row">
                            <div className="col"><img src={Images.microphonePNG} alt=""/>{debate?.status}</div>
                            <div className="col text-end"><img src={Images.dot} alt=""/></div>
                            <div className="col-md-12 mt-4">
                              <h3><b>{debate?.topic}</b></h3>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div className="debate-winner">
                            <div className="row">
                              <div className="d-flex justify-content-between">
                                <p className="m-0 losing-tag">Debater</p>
                                <p className="m-0 losing-tag">Debatee</p>
                              </div>
                              <div className="col d-flex justify-content-between align-items-end ">
                                <div className="user d-flex align-items-center mt-3">
                                  <img className="user-img" src={debate?.debater?.image} style={{borderRadius: '40%'}} alt="" />
                                  <div className="user-detail text-white ps-2">
                                    <p className="m-0 name"><b>{debate?.debater?.name}</b></p>
                                    <p className="m-0 username">{debate?.debater?.userName}</p>
                                  </div>
                                </div>
                                <img src={Images.Versus} alt=""/>
                                <div className="user d-flex justify-content-end align-items-center mt-3">
                                  <div className="user-detail text-white text-end px-2">
                                    <p className="m-0 name"><b>{debate?.debatee?.name}</b></p>
                                    <p className="m-0 username">{debate?.debatee?.userName}</p>
                                  </div>
                                  <img className="user-img" style={{borderRadius: '40%'}} src={debate?.debatee?.image} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Link className="create-debate" to="/create-beef"><span className="material-symbols-outlined add">
              add
            </span></Link>
        </div>
      </main>
    </div>
  )
}

export default BeefList
