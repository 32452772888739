import React, { createContext, useContext, useMemo } from 'react';
import getSocket from './socket';

const SocketContext = createContext();

export const SocketProvider = ({ userId, children }) => {
  const socket = useMemo(() => getSocket({ userId }), [userId]);

  return (
    <SocketContext.Provider value={socket}>
      {children}
    </SocketContext.Provider>
  );
};

export const useSocket = () => useContext(SocketContext);