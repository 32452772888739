import axios from 'axios'

axios.defaults.baseURL = "https://admin.thesoapboxx.app/api/v1/";
// axios.defaults.baseURL = "http://localhost:4002/v1/";
// axios.defaults.baseURL = process.env.API_URL;
console.log('default', axios.defaults.baseURL);
if (sessionStorage.getItem('data')) {
    const data = JSON.parse(sessionStorage.getItem('data'));
    axios.defaults.headers.common = {'Authorization' : `Bearer ${data.token}`}
}
export default axios;