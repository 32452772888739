import { Navigate } from 'react-router-dom';

const DefaultRoute = () => {
  function getToken(){
    const tokenString = sessionStorage.getItem("data");
    const data = JSON.parse(tokenString);
    if(!data) return false;
    if(data.token === undefined){
      return false
    }else{
      return data.token;
    }
  }
  const token = getToken();

  return token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />;
};
  
export default DefaultRoute;
