import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.css';
import homeSVG from "../assets/imgs/home.svg"
import notificationBing from "../assets/imgs/notification-bing.svg"
import microphoneSVG from "../assets/imgs/microphone.svg"
import note2 from "../assets/imgs/note-2.svg"
import profileCircle from "../assets/imgs/profile-circle.svg"
import broadcast from "../assets/imgs/Broadcast.svg"
import message from "../assets/imgs/message.svg"
import message3 from "../assets/imgs/messages-3.svg"
import moneySend from "../assets/imgs/money-send.svg"
import "../assets/css/style.css"
import { useDarkMode } from '../contexts/DarkModeContext';

const Sidebar = () => {
  const location = useLocation();
  const { darkMode, toggleDarkMode } = useDarkMode(); 


  const menuItems = [
    { to: "/dashboard", img: homeSVG, text: "Feeds" },
    { to: "/activity", img: notificationBing, text: "Activity" },
    { to: "/beef", img: microphoneSVG, text: "Beef" },
    { to: "/petition", img: note2, text: "Petition" },
    { to: "/profile", img: profileCircle, text: "Profile" },
    { to: "/townhall", img: broadcast, text: "Town Hall" },
    { to: "/messages", img: message, text: "Messages" },
    { to: "/groups", img: message3, text: "Groups" },
    { to: "/rewards", img: moneySend, text: "Rewards" }
  ];

  return (
    <aside id="sidebar" className={`sidebar ${darkMode ? 'dark-mode' : ''}`}>
      <div className="h-100">
        <div className="search-input">
          <i className="bi bi-search"></i>
          <input className="search" type="search" placeholder="search" />
        </div>
        <ul className="sidebar-nav">
          {menuItems.map((item, index) => (
            <li key={index} className={`sidebar-item ${location.pathname === item.to ? 'active' : ''}`}>
              <Link to={item.to} className="sidebar-link">
                <img src={item.img} alt="" />
                {item.text}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </aside>
  )
}

export default Sidebar
