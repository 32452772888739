// AppRoutes.js
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './components/routing/PrivateRoute';
import routes from './routes';
import DefaultRoute from './components/routing/DefaultRoutes';

const AppRoutes = () => {
  return (
    <Routes>
    <Route path="/" element={<DefaultRoute />} />
      {routes.map(({ path, element, protected: isProtected, dashboard }, index) => {
        if (isProtected) {
          return (
            <Route
              key={index}
              path={path}
              exact
              element={
                <PrivateRoute dashboard={dashboard}>
                  {element}
                </PrivateRoute>
              }
            />
          );
        }
        return <Route key={index} path={path} element={element} />;
      })}
    </Routes>
  );
};

export default AppRoutes;
