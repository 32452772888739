// socket.js

import { io } from 'socket.io-client';
const getSocket = query => io("https://admin.thesoapboxx.app/", {query});
// const getSocket = query => io("http://localhost:4002/", {query});
if(getSocket){
    console.log("Socket Connected");
} else {
    console.log("Socket Not Connected");
}

export default getSocket;
