import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from '../../config/server';
import { CLEAR_SELECTED_USERS, CLEAR_SELECTED_TOPICS } from '../actions';

const initialState = {
    isAuthenticated: false,
    Loading: true,
    selectedCategories: [],
    selectedQOD : [],
    User : {},
    newUser : {},
    selectedTopics : [],
    selectedUsers : [],
    SelectedDeabate : [{}],
}
export const registerUser = createAsyncThunk(
    'data/registerUser',
    async (userData, { rejectWithValue }) => {
        try {
            const response = await axios.post('/users/', userData);
            return response.data.data; 
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);
export const Data = createSlice({
    name : "data",
    initialState,
    reducers : {
        setUser : (state, action) => {
            return {
                ...state,
                User : action.payload,
                isAuthenticated : true
            }
        },
        setCategories : (state, action) => {
            if (state.selectedCategories.includes(action.payload)) {
                return {
                    ...state,
                    selectedCategories : state.selectedCategories.filter((item) => item!== action.payload)
                }
            }else{
            return {
               ...state,
                selectedCategories : [...state.selectedCategories, action.payload]
            }
        }
        },
        setQOD : (state, action) => {
            if (state.selectedQOD.includes(action.payload)) {
                console.log("In If Condition");

                return {
                    
                    ...state,
                    selectedQOD : state.selectedQOD.filter((item) => item!== action.payload)
                }
            }else{
                console.log("In else  Condition", state.selectedQOD);

            return {
               ...state,
                selectedQOD : [state.selectedQOD, action.payload]
            }
        }
        },
        setNewUser : (state, action) => {
            console.log('setNewUser', action);
            return {
               ...state,
                newUser : action.payload
            }
        },
        registerUser : (state, action) => {

        },
        setTopics : (state, action) => {
            return {
                ...state,
                selectedTopics : action.payload
            }
        },
        setUsers : (state, action) => {
            return {
                ...state,
                selectedUsers : action.payload
            }
        },
        setSelectedDeabate : (state, action) => {
            return {
                ...state,
                SelectedDeabate : action.payload
            }
        },
         // Reducers to handle clearing data
         clearSelectedUsers: (state) => {
            state.selectedUsers = [];
        },
        clearSelectedTopics: (state) => {
            state.selectedTopics = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(registerUser.pending, (state) => {
                state.Loading = true;
            })
            .addCase(registerUser.fulfilled, (state, action) => {
                state.User = action.payload;
                state.isAuthenticated = true;
                state.Loading = false;
            })
            .addCase(registerUser.rejected, (state, action) => {
                state.Loading = false;
                // handle error state
                console.error('Registration failed:', action.payload);
            });
    }
}) 

export const { setUser, setCategories, setQOD, setNewUser, setTopics, setUsers, setSelectedDeabate, clearSelectedUsers, clearSelectedTopics }  = Data.actions
export default Data.reducer;