import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import TopNav from '../navbar';
import Sidebar from '../sidebar';

const PrivateRoute = ({ children, dashboard }) => {
  const location = useLocation();

  function getToken() {
    const tokenString = sessionStorage.getItem('data');
    const data = JSON.parse(tokenString);
    if (!data) return false;
    return data.token !== undefined ? data.token : false;
  }

  const token = getToken();
  if (!token) {
    return <Navigate to="/login" />;
  }

  const shouldHideSidebar = location.pathname === '/group';


  return dashboard ? (
    <div>
      <TopNav />
      {!shouldHideSidebar && <Sidebar />}
      {children}
    </div>
  ) : (
    children
  );
};

export default PrivateRoute;
