export const Images = {
    logo : require('../assets/imgs/Logo.png'),
    dot : require('../assets/imgs/3 dot.png'),
    boxing : require('../assets/imgs/Boxing.png'),
    broadcast : require('../assets/imgs/Broadcast.svg'),
    buddhism : require('../assets/imgs/Buddhism.png'),
    cameraVideoPNG : require('../assets/imgs/CameraVideo.png'),
    cameraVideoSvg : require('../assets/imgs/CameraVideo.svg'),
    christianity : require('../assets/imgs/Christianity.png'),
    cricket : require('../assets/imgs/Cricket.png'),
    emojiNeutral : require('../assets/imgs/EmojiNeutral.svg'),
    exclude : require('../assets/imgs/Exclude.png'),
    football : require('../assets/imgs/Football.png'),
    Health : require('../assets/imgs/Health.png'),
    Heart : require('../assets/imgs/Heart.svg'),
    Hockey : require('../assets/imgs/Hockey.png'),
    imageInChatBubble : require('../assets/imgs/left__hero__image.png'),
    Lifestyle : require('../assets/imgs/Lifestyle.png'),
    Muslims : require('../assets/imgs/Muslims.png'),
    Spirituality : require('../assets/imgs/Spirituality.png'),
    Taoism : require('../assets/imgs/Taoism.png'),
    Vector : require('../assets/imgs/Vector.png'),
    WWE : require('../assets/imgs/WWE.png'),
    VersusLarge : require('../assets/imgs/Versus large.png'),
    verified : require('../assets/imgs/verify.jpg'),
    Versus : require('../assets/imgs/Versus.png'),
    apple : require('../assets/imgs/apple.png'),
    basketbal : require('../assets/imgs/basketbal.png'),
    google : require('../assets/imgs/google.png'),
    facebook : require('../assets/imgs/facebook.png'),
    bluelogo : require('../assets/imgs/bluelogo.png'),
    homeSVG : require('../assets/imgs/home.svg'),
    notificationBing : require('../assets/imgs/notification-bing.svg'),
    microphoneSVG : require('../assets/imgs/microphone.svg'),
    note2 : require('../assets/imgs/note-2.svg'),
    profileCircle : require('../assets/imgs/profile-circle.svg'),
    message : require('../assets/imgs/message.svg'),
    message3 : require('../assets/imgs/messages-3.svg'),
    moneySend : require('../assets/imgs/money-send.svg'),
    profileImage : require('../assets/imgs/profile img.png'),
    cardImage2 : require('../assets/imgs/card img 2.jpg'),
    microphonePNG : require('../assets/imgs/microphone.png'),
    user2 : require('../assets/imgs/user 2.png'),
    user1 : require('../assets/imgs/user 1.png'),
    calendar : require('../assets/imgs/calendar.png'),
    imgAvatar : require('../assets/imgs/img_avatar.png'),
    shareAlt : require('../assets/imgs/share-alt.png'),
    shareIcon : require('../assets/imgs/share-icon.png'),
    videoSlash : require('../assets/imgs/video-slash.png'),
    RefreshCircle : require('../assets/imgs/refresh-circle.png'),
    Exclude: require('../assets/imgs/Exclude.png'),
    Star: require('../assets/imgs/star.png'),
    
    
    user : require('../assets/imgs/user.png'),
    smallUser : require('../assets/imgs/small user.png'),
    messageGreen : require('../assets/imgs/message-geen.png'),
    likePNG : require('../assets/imgs/like.png'),
    Play : require('../assets/imgs/Play.png'),
    pencil : require('../assets/imgs/PencilSquare.png'),
    messages : require('../assets/imgs/messageIcon.png'),
    arrowBack : require('../assets/imgs/arrow.png'),
    plus : require('../assets/imgs/plus.jpg'),
    eyes : require('../assets/imgs/eye.png'),
    live1 : require('../assets/imgs/live1.png'),
    live2 : require('../assets/imgs/live2.png'),
    camera : require('../assets/imgs/camera.png'),
    video : require('../assets/imgs/Video.png'),
}

export const NOTIFICATION_TYPE = {
    debate: 'Debate',
    user: 'Users',
    post: 'Posts',
    petition: 'Petitions',
    chat: 'Chats',
    comment:'Comments',
    group : 'Groups',
    podcast : 'Podcasts'
}