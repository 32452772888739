import { lazy } from 'react';
import BeefList from './pages/beef/BeefList';
const Login = lazy(() => import('./pages/auth/Login'));
const ForgetPassword = lazy(() => import('./pages/auth/ForgetPassword'));
const VerifyForgotPassword = lazy(() => import('./pages/auth/VerifyForgotPassword'));
const ResetPassword = lazy(() => import('./pages/auth/ResetPassword'));

const SignUp = lazy(() => import('./pages/auth/SignUp'));
const NameScreen = lazy(() => import('./pages/auth/NameScreen'));
const Password = lazy(() => import('./pages/auth/Password'));
const Gender = lazy(() => import('./pages/auth/Gender'));
const UserName = lazy(() => import('./pages/auth/UserName'));
const TermsAndPolicies = lazy(() => import('./pages/auth/TermsAndPolicies'));
const SuggestedUsers = lazy(() => import('./pages/auth/SuggestedUsers'));
const Categories = lazy(() => import('./pages/interest/Categories'));
const SubCategories = lazy(() => import('./pages/interest/SubCategories'));
const QODCategories = lazy(() => import('./pages/qod/QODCategories'));
const QODSubCategories = lazy(() => import('./pages/qod/QODSubCategories'));

// Beef

const CreateBeef = lazy(() => import('./pages/beef/CreateBeef'));
const AddDebater = lazy(() => import('./pages/beef/AddDebater'));
const TagUsers = lazy(() => import('./pages/beef/TagUsers'));
const AddCategories = lazy(() => import('./pages/beef/AddCategories'));
const AddSubCategories = lazy(() => import('./pages/beef/AddSubCategories'));
const LivePage = lazy(() => import('./pages/beef/LiveDebates'));

// Podcast 
const GoLive = lazy(() => import('./pages/townhall/createPodCast'));
const TownHall = lazy(() => import('./pages/townhall/TownHall'));
const UploadPodcast = lazy(() => import('./pages/townhall/UploadPodcast'));
const LivePodCast = lazy(() => import('./pages/townhall/LivePodcast'));
const AddCategory = lazy(() => import('./pages/townhall/AddCategory'));
const AddGuest = lazy(() => import('./pages/townhall/AddGuest'));

// Settings
const Settings = lazy(() => import('./pages/settings/Index'));
const ChangePassword = lazy(() => import('./pages/settings/ChangePassword'));
const VerifyEmailAdres = lazy(() => import('./pages/settings/VerifyEmailAdres'));
const VerifyMobile = lazy(() => import('./pages/settings/VerifyPhone'));
const VerifyOTP = lazy(() => import('./pages/settings/ConfirmOTP'));
const SelectQodCategory = lazy(() => import('./pages/settings/SelectQodCategory'));
const SelectSubQodCategory = lazy(() => import('./pages/settings/SelectQod'));
const SelectInterestCategory = lazy(() => import('./pages/settings/SelectInterestCategory'));
const SelectSubInterestCategory = lazy(() => import('./pages/settings/SelectInterest'));

// Privacy 
const Privacy = lazy(() => import('./pages/privacy/Privacy'));

// Rewards 
const Rewards = lazy(() => import('./pages/rewards/Main'));


const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const Petition = lazy(() => import('./pages/petition/Petition'));
const Activity = lazy(() => import('./pages/activity/Activity'));
const Profile = lazy(() => import('./pages/profile/Profile'));
const EditProfile = lazy(() => import('./pages/profile/EditProfile'));
const CreatePost = lazy(() => import('./pages/profile/CreatePost'));
const Groups = lazy(() => import('./pages/groups/Groups'));
const CreateGroup = lazy(() => import('./pages/groups/CreateGroup'));
const GroupDetails = lazy(() => import('./pages/groups/GroupDetails'));
const Message = lazy(() => import('./pages/messages/Message'));
const MessageUserList = lazy(() => import('./pages/messages/MessageUserList'));
const SingleUserChat = lazy(() => import('./pages/messages/SingleUserChat'));


const routes = [
  { path: '/', element: <Dashboard />, protected: true, dashboard : true },
  { path: '/login', element: <Login />, protected: false },
  { path: '/forgot-password', element: <ForgetPassword />, protected: false },
  { path: '/verify-otp', element: <VerifyForgotPassword />, protected: false },
  { path: '/reset-password', element: <ResetPassword />, protected: false },
  { path: '/signup', element: <SignUp />, protected: false },
  { path: '/signup/name', element: <NameScreen />, protected: false },
  { path: '/signup/password', element: <Password />, protected: false },
  { path: '/signup/gender', element: <Gender />, protected: false },
  { path: '/signup/username', element: <UserName />, protected: false },
  { path: '/signup/terms', element: <TermsAndPolicies />, protected: false },
  { path: '/suggested-users', element: <SuggestedUsers />, protected: false },
  { path: '/categories', element: <Categories />, protected: false },
  { path: '/sub-categories', element: <SubCategories />, protected: false },
  { path: '/qod-categories', element: <QODCategories />, protected: false },
  { path: '/qod-subcategories', element: <QODSubCategories />, protected: false },
  { path: '/dashboard', element: <Dashboard />, protected: true, dashboard : true },

// beef

  { path: '/beef', element: <BeefList />, protected: true, dashboard : true },
  { path: '/create-beef', element: <CreateBeef />, protected: true, dashboard : true },
  { path: '/adddebater', element: <AddDebater />, protected: true, dashboard : true },
  { path: '/addtopics', element: <AddCategories />, protected: true, dashboard : true },
  { path: '/addsubtopics', element: <AddSubCategories />, protected: true, dashboard : true },
  { path: '/tagpeople', element: <TagUsers />, protected: true, dashboard : true },
  { path: '/live', element: <LivePage />, protected: true, dashboard : true },

// podcast 

  { path: '/townhall', element: <TownHall />, protected: true, dashboard : true },
  { path: '/go-live', element: <GoLive />, protected: true, dashboard : true },
  { path: '/upload-podcast', element: <UploadPodcast />, protected: true, dashboard : true },
  { path: '/live-podcast', element: <LivePodCast />, protected: true, dashboard : true },
  { path: '/add-category', element: <AddCategory />, protected: true, dashboard : true },
  { path: '/add-guest', element: <AddGuest />, protected: true, dashboard : true },

  // Settings
  { path: '/settings', element: <Settings />, protected: true, dashboard : true },
  { path: '/settings/change-password', element: <ChangePassword />, protected: true, dashboard: true },
  { path: '/settings/verify-email', element: <VerifyEmailAdres />, protected: true, dashboard: true },
  { path: '/settings/verify-mobile', element: <VerifyMobile />, protected: true, dashboard: true },
  { path: '/settings/verify-otp', element: <VerifyOTP />, protected: true, dashboard: true },
  { path: '/settings/qod-categories', element: <SelectQodCategory />, protected: true, dashboard: true },
  { path: '/settings/select-sub-qod', element: <SelectSubQodCategory />, protected: true, dashboard: true },
  { path: '/settings/interest-categories', element: <SelectInterestCategory />, protected: true, dashboard: true },
  { path: '/settings/select-sub-interest', element: <SelectSubInterestCategory />, protected: true, dashboard: true },
  

  // Privacy
  { path: '/privacy', element: <Privacy />, protected: true, dashboard : true },

  // Rewards
  { path: '/rewards', element: <Rewards />, protected: true, dashboard : true },

  { path: '/petition', element: <Petition />, protected: true, dashboard : true },
  { path: '/activity', element: <Activity />, protected: true, dashboard : true },
  { path: '/profile', element: <Profile />, protected: true, dashboard : true },
  { path: '/profile/edit', element: <EditProfile />, protected: true, dashboard : true },
  { path: '/groups', element: <Groups />, protected: true, dashboard : true },
  { path: '/groups/create', element: <CreateGroup />, protected: true, dashboard : true },
  { path: '/group', element: <GroupDetails />, protected: true, dashboard : true },
  { path: '/messages', element: <Message />, protected: true, dashboard : true },
  { path: '/users', element: <MessageUserList />, protected: true, dashboard : true },
  { path: '/chat', element: <SingleUserChat />, protected: true, dashboard : true },
  { path: '/upload', element: <CreatePost />, protected: true, dashboard : true },
];

export default routes;
