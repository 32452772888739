import React, { useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Images } from '../store/Constants';
import '../assets/css/style.css';
import { useSocket } from '../SocketContext';
import { useDarkMode } from '../contexts/DarkModeContext';

const Navbar = () => {
  const { User } = JSON.parse(sessionStorage.getItem("data"));
  const socket = useSocket();
  const { darkMode, toggleDarkMode } = useDarkMode(); 

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    const sidebar = document.querySelector("#sidebar");
    const restrictedRoutes = ['/group', '/chat', '/live', '/go-live', '/settings/qod-categories', '/settings/select-sub-qod', '/settings/select-sub-interest', '/settings/interest-categories'];
    if (restrictedRoutes.includes(location.pathname)) {
      sidebar?.classList.add("collapse");
    } else {
      sidebar?.classList.remove("collapse");
    }
  }, [location.pathname]);

  const handleLogout = () => {
    sessionStorage.removeItem('data');
    navigate('/login');
  };

  const handleCollapse = () => {
    const sidebar = document.querySelector("#sidebar");
    if (sidebar) {
      sidebar.classList.toggle("collapse");
    }
  };

  const restrictedRoutes = ['/group', '/chat', '/live', '/go-live', '/settings/qod-categories', '/settings/select-sub-qod', '/settings/interest-categories', '/settings/select-sub-interest'];
  const isRestrictedRoute = restrictedRoutes.includes(location.pathname);

  return (
    <nav className={`navbar ${darkMode ? 'dark-mode' : ''} navbar-expand px-3 border-bottom`}>
      <button 
        onClick={handleCollapse} 
        className={`btn toggle-btn me-3 ${darkMode ? 'btn-light' : ''}`} 
        type="button" 
        disabled={isRestrictedRoute}
      >
        <span className={`navbar-toggler-icon ${darkMode ? 'toggler-icon-dark' : 'toggler-icon-light'}`}></span>
      </button>
      <Link className="navbar-brand" to="">
        <img src={Images.bluelogo} alt="" />
      </Link>

      <div className="dropdown profile-dropdown">
        <button className="btn profile-btn" type="button" data-bs-toggle="dropdown">
          <img src={User?.image} alt="" className="profile-img" />
        </button>
        <ul className={`dropdown-menu ${darkMode ? 'dark-mode' : ''}`}>
          <li><Link className="dropdown-item" to="/profile">View Profile</Link></li>
          <li><Link className="dropdown-item" to="/privacy">Privacy</Link></li>
          <li><Link className="dropdown-item" to="/settings">Setting</Link></li>
          <li><p className="dropdown-item" style={{cursor : "pointer"}} onClick={handleLogout}>Logout</p></li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
