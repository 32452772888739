import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import { DarkModeProvider } from './contexts/DarkModeContext';


const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
     <AgoraRTCProvider client={client}>
      <DarkModeProvider>
    <App />
    </DarkModeProvider>
    </AgoraRTCProvider>
  // </React.StrictMode>
);
