import './App.css';
import { Provider } from 'react-redux';
import { store } from './store/store';
import { BrowserRouter as Router } from 'react-router-dom';
import { Suspense } from 'react';
import AppRoutes from './AppRoutes';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "toastr/build/toastr.min.css";
import { SocketProvider } from './SocketContext';
import PuffLoader from 'react-spinners/PuffLoader';
import { useDarkMode } from './contexts/DarkModeContext';

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function Loading({ darkMode }) {
  return (
    <div style={{
       position: "absolute",
       top: "0",
       left: "0",
       width: "100%",
       height: "100%",
       backgroundColor: darkMode ? "#333" : "#fff",
       display: "flex",
       alignItems: "center",
       justifyContent: "center"
    }}>
      <PuffLoader
        color='#73B8E1'
        cssOverride={override}
        size={150}
      />
    </div>
  )
}

function App() {
  const { darkMode } = useDarkMode();
  const User = JSON.parse(sessionStorage.getItem("data"))?.User;
  const userId = User?._id || null;

  return (
    <SocketProvider userId={userId}>
      <Provider store={store} className="App">
        <Router>
          <Suspense fallback={<Loading darkMode={darkMode} />}>
            <AppRoutes />
          </Suspense>
        </Router>
      </Provider>
    </SocketProvider> 
  );
}

export default App;
